import React, { ChangeEvent, useEffect, useState } from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";

interface WeightInputProps {
  value: Weight;
  onChange: (value: Weight) => void;
  hiddenLabel?: boolean;
  showGramsConversion?: boolean;
  horizontalLayout?: boolean;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function WeightInput({
  value,
  onChange,
  hiddenLabel = false,
  showGramsConversion = false,
  horizontalLayout = false,
  onBlur
}: WeightInputProps) {
  const [pounds, setPounds] = useState(value?.pounds || 0);
  const [ounces, setOunces] = useState(value?.ounces?.toFixed(2) || "0.00");

  useEffect(() => {
    if (value) {
      setPounds(value.pounds);
      setOunces(value.ounces.toFixed(2));
    }
  }, [value]);

  const handlePoundsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parsedInput = parseInt(e.target.value);
    if (isNaN(parsedInput)) {
      console.error("Pounds must be a number");
      return;
    }
    const newPounds = Math.max(0, parsedInput || 0);
    setPounds(newPounds);
    updateWeight(newPounds, parseFloat(ounces));
  };

  const handleOuncesChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newOunces = e.target.value;
    setOunces(newOunces);
  };

  const handleOuncesBlur = (e: ChangeEvent<HTMLInputElement>) => {
    let newOunces = parseFloat(ounces);
    if (isNaN(newOunces)) {
      newOunces = 0;
    }
    updateWeight(pounds, newOunces);
    onBlur && onBlur(e);
  };

  const updateWeight = (lbs: number, oz: number) => {
    let totalOunces = lbs * 16 + oz;
    let newPounds = Math.floor(totalOunces / 16);
    let newOunces = totalOunces % 16;

    setPounds(newPounds);
    setOunces(newOunces.toFixed(2));

    onChange({
      pounds: newPounds,
      ounces: parseFloat(newOunces.toFixed(2)),
      grams: Math.round(totalOunces * 28.34952)
    });
  };

  return (
    <div className={styles.WeightInput} data-cy="weight_input">
      <div className={clsx({ [styles.horizontalLayout]: horizontalLayout })}>
        <label htmlFor="pounds" style={hiddenLabel ? { display: "none" } : {}}>
          Weight
        </label>
        <div className={styles.weightInputContainer}>
          <div className={styles.inputWithUnit}>
            <input
              type="number"
              id="pounds"
              name="pounds"
              placeholder="lbs"
              min="0"
              required
              onChange={handlePoundsChange}
              value={pounds}
              onFocus={e => e.target.select()}
            />
            <span>lbs</span>
          </div>
          <div className={styles.inputWithUnit}>
            <input
              type="text"
              id="ounces"
              name="ounces"
              placeholder="oz"
              required
              onChange={handleOuncesChange}
              onBlur={handleOuncesBlur}
              value={ounces}
              onFocus={e => e.target.select()}
            />
            <span>oz</span>
          </div>
        </div>
      </div>
      {showGramsConversion && (
        // 28.34952 is the conversion constant for converting ounces to grams
        <p>
          Grams: {Math.round((pounds * 16 + parseFloat(ounces)) * 28.34952)}
        </p>
      )}
    </div>
  );
}

export function getWeightObjectFromGrams(grams: number): Weight {
  if (!grams || isNaN(grams)) {
    return {
      pounds: 0,
      ounces: 0,
      grams: 0,
    } as Weight;
  }
  const totalOunces = parseFloat((grams / 28.34952).toFixed(2));
  const pounds = Math.floor(totalOunces / 16);
  const ounces = parseFloat((totalOunces - pounds * 16).toFixed(2));
  const weight: Weight = {
    pounds,
    ounces,
    grams
  };
  return weight;
}

export type Weight = {
  pounds: number;
  ounces: number;
  grams: number;
};
