/* MonthContent Component*/
/* External Imports */
import clsx from "clsx";
import { MouseEvent, useCallback } from "react";
import Skeleton from "react-loading-skeleton";

/* Local Imports */

// components

// constants
import {
  appointmentTypeToHexCode,
  getTextColorForBackground,
  userIdToHexCode
} from "../utils";
import { FORMAT } from "@/globals/helpers/formatters";

// store
import { AppointmentInfo, AppointmentList } from "@/store/services/scheduling";
import { usePracticeListAppointmentTypesQuery } from "@/store/services/generated/practice";

// utils
import { useColorByProvider } from "@/globals/helpers/customHooks";
// styles
import styles from "../styles.module.scss";

/* MonthContent Typescript Interface */
interface MonthContentProps {
  appointments: AppointmentList;
  handleAppointmentClick: (
    appointment: AppointmentInfo,
    e: MouseEvent<HTMLButtonElement>
  ) => void;
  handleSeeMoreClick: (timestamp: string) => void;
  timestamp: string;
  additionalApptsCount: number;
  isLoading: boolean;
}

export default function MonthContent({
  appointments,
  handleAppointmentClick,
  handleSeeMoreClick,
  timestamp,
  additionalApptsCount,
  isLoading
}: MonthContentProps) {
  /* Redux */
  const { data: appointmentTypes } = usePracticeListAppointmentTypesQuery();
  const colorByProvider = useColorByProvider();
  /* Local State */

  const backgroundColor = useCallback(
    (appointment: AppointmentInfo) => {
      return colorByProvider
        ? userIdToHexCode(appointment.provider.user_id)
        : appointmentTypeToHexCode(
            appointment.appointment_type?.appointment_type,
            appointmentTypes?.find(
              type => type.appointment_type === appointment.appointment_type?.appointment_type
            )?.appointment_hex
          );
    },
    [appointments, appointmentTypes, colorByProvider]
  );
  /* Effects */

  /* Event Handlers */

  return (
    <div className={clsx(styles.MonthContent)}>
      {!isLoading ? (
        appointments?.map(
          (appointment, index) =>
            index < 2 && (
              <button
                key={appointment?.appointment_id}
                onClick={e => handleAppointmentClick(appointment, e)}
              >
                <p
                  className={clsx(styles.apptSummary)}
                  style={{
                    color: getTextColorForBackground(
                      backgroundColor(appointment)
                    ),
                    borderColor: backgroundColor(appointment),
                    backgroundColor: backgroundColor(appointment)
                  }}
                >
                  {appointment.patient ? FORMAT.name(appointment.patient) : ""}
                </p>
              </button>
            )
        )
      ) : (
        <Skeleton
          height={"100%"}
          width={"90%"}
          style={{ marginLeft: "3px", borderRadius: "2px" }}
        />
      )}
      {!!additionalApptsCount && (
        <button onClick={() => handleSeeMoreClick(timestamp)}>
          <p className={clsx(styles.apptSummary, "tMd")}>
            {additionalApptsCount} more {"->"}
          </p>
        </button>
      )}
    </div>
  );
}
