import { Weight } from "@/components/input/weightInput";
import { UserInfo } from "@/store/services/user";
import dayjs from "dayjs";

export interface NewbornExamBaseValues {
  version: string;
  start_time: number; // YYYYMMDDHHmmss
  select_provider?: UserInfo;
  respiratory_rate: string;
}

export interface NewbornExamDemographicValues {
  // Demographics
  first_name: string;
  last_name: string;
  birth_date: string;
  birth_time: string;
  baby_sex: string;
}

export interface NewbornExamGrowthValues {
  // Growth
  birth_weight: Weight;
  baby_chest_width: string;
  standing_height: string;
  head_circumference: string;
  recumbent_length: string;
  EGO: string;
  baby_heart_rate: string;
}

export interface NewbornExamPhysicalValues {
  general_appearance: string;
  // TODO(Landon): Stronger typing here based on the options
  skin: Record<string, any> | string;
  head: Record<string, any> | string;
  eyes: Record<string, any> | string;
  vit_k: Record<string, any> | string;
  ENT: Record<string, any> | string;
  eye_proph: Record<string, any> | string;
  thorax: string;
  abdomen: Record<string, any> | string;
  heart: Record<string, any> | string;
  femoral_pulses: Record<string, any> | string;
  muscle_tone: Record<string, any> | string;
  genitals: Record<string, any> | string;
  spine: Record<string, any> | string;
  lungs: Record<string, any> | string;
  extremeties: Record<string, any> | string;
  birthmarks: Record<string, any> | string;
  head_neck: Record<string, any> | string;
  red_reflexes: Record<string, any> | string;
  mouth_palate: Record<string, any> | string;
  ears: Record<string, any> | string;
  heart_sounds: Record<string, any> | string;
  pulses: Record<string, any> | string;
  umbilicus: Record<string, any> | string;
  genitourinary_male: Record<string, any> | string;
  genitourinary_female: Record<string, any> | string;
  anus: Record<string, any> | string;
  hips: Record<string, any> | string;
  clavicles: Record<string, any> | string;
  musculoskeletal: Record<string, any> | string;
}

export interface NewbornExamReflexesValues {
  comments: string;
  babinksi: string;
  palmar: string;
  plantar: string;
  moro: string;
  sucking: string;
  swallowing: string;
  step: string;
  tonic_neck: string;
}

export interface NewbornExamBallardValues {
  gest_age: Record<string, any> | string;
  vernix: Record<string, any> | string;
  breast_tissue: Record<string, any> | string;
  ear_form: Record<string, any> | string;
  ear_cart: Record<string, any> | string;
  sole_creases: Record<string, any> | string;
  skin_appearance: Record<string, any> | string;
  nail_plates: Record<string, any> | string;
  hair: Record<string, any> | string;
  lanugo: Record<string, any> | string;
  labia: Record<string, any> | string;
  testes: Record<string, any> | string;
  scrotum: Record<string, any> | string;
  skull_firm: Record<string, any> | string;
}

export type ExamType = "LABOR" | "ENCOUNTER";

export interface NewbornExamEncounterValues
  extends NewbornExamBallardValues,
    NewbornExamBaseValues,
    NewbornExamPhysicalValues,
    NewbornExamReflexesValues {}

export interface NewbornExamLaborValues
  extends NewbornExamEncounterValues,
    NewbornExamGrowthValues,
    NewbornExamDemographicValues {}

export const GetNewbornExamDefaultValues = (
  type: ExamType,
  previousExamData?: NewbornExamEncounterValues | NewbornExamLaborValues
): NewbornExamEncounterValues | NewbornExamLaborValues => {
  // We declare an encounter exam values because labor has everything
  // in the encounter exam plus some extra fields.
  let examValues: NewbornExamEncounterValues = {
    version: "",
    // Default to right now
    start_time: parseInt(dayjs.utc().format("YYYYMMDDHHmmss")),
    // version 1 data
    general_appearance: "",
    skin: "",
    head: "",
    eyes: "",
    vit_k: "",
    ENT: "",
    eye_proph: "",
    thorax: "",
    abdomen: "",
    heart: "",
    femoral_pulses: "",
    muscle_tone: "",
    genitals: "",
    spine: "",
    lungs: "",
    extremeties: "",
    babinksi: "",
    palmar: "",
    plantar: "",
    moro: "",
    sucking: "",
    swallowing: "",
    step: "",
    tonic_neck: "",
    gest_age: "",
    vernix: "",
    breast_tissue: "",
    ear_form: "",
    ear_cart: "",
    sole_creases: "",
    skin_appearance: "",
    nail_plates: "",
    hair: "",
    lanugo: "",
    labia: "",
    testes: "",
    scrotum: "",
    skull_firm: "",
    // version 2 data
    birthmarks: "",
    head_neck: "",
    heart_sounds: "",
    mouth_palate: "",
    musculoskeletal: "",
    red_reflexes: "",
    clavicles: "",
    ears: "",
    pulses: "",
    umbilicus: "",
    genitourinary_female: "",
    genitourinary_male: "",
    anus: "",
    hips: "",
    respiratory_rate: "",
    comments: "",
  };

  if (type == "LABOR") {
    examValues = {
      ...examValues,
      first_name: "",
      last_name: "",
      birth_date: "",
      birth_time: "",
      baby_sex: "",
      birth_weight: {
        pounds: 0,
        ounces: 0,
        grams: 0
      },
    } as NewbornExamLaborValues;
  }

  if (previousExamData) {
    // We overwrite the defaults with whatever the previousExamData has
    // then cast the exam value as the previousExamData type. This is to
    // ensure we never get type errors if the previousExamData is a
    // different type
    examValues = {
      ...examValues,
      ...previousExamData
    } as typeof previousExamData;
  }

  return examValues;
};
