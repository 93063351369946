import clsx from "clsx";

import styles from "./styles.module.scss";

interface KeyValueGridProps {
  children: React.ReactNode;
  twoColumn?: boolean;
  matchInputHeight?: boolean;
  horizontal?: boolean;
}

export default function KeyValueGrid({ children, twoColumn = false, matchInputHeight = false, horizontal = false }: KeyValueGridProps) {
  return <div className={clsx(styles.KeyValueGrid, {[styles.twoColumn]: twoColumn, [styles.matchInputHeight]: matchInputHeight, [styles.horizontal]: horizontal})}>{children}</div>;
}
