// External
import { ForwardedRef, forwardRef, useImperativeHandle, useMemo } from "react";
import Select from "react-select";

// Constants
import { NEWBORN_FORM } from "../constants";

// Components
import ControlledCombobox from "@/components/input/controlledCombobox";
import ControlledOptionsField from "../../generated/options";
import { NewbornFormPartsProps } from "./newborn";
import Input from "@/components/input";

// Styles
import styles from "../styles.module.scss";
import Button from "@/components/button";
import { usePatientGetInfoQuery } from "@/store/services/patient";
import {
  SelectDefaultStyles,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";
import { Controller, useForm } from "react-hook-form";

interface NewbornPhysicalProps extends Partial<NewbornFormPartsProps> {
  newbornId: number;
}

export default forwardRef(function NewbornPhysical(
  { disabled = false, previousExamData, newbornId }: NewbornPhysicalProps,
  ref: ForwardedRef<any>
) {

  const form = useForm();
  const {
    control,
    formState: { errors },
    getValues
  } = form;

  const { data: infantInfo } = usePatientGetInfoQuery({
    patientId: newbornId
  });

  const isVersion2 = useMemo(() => {
    return !(previousExamData && previousExamData?.version !== "2");
  }, [previousExamData]);

  const formFields = useMemo(() => {
    // If we don't know the sex, don't filter at all.
    if (infantInfo?.sex === "UNKNOWN") {
      return NEWBORN_FORM.physical_v2;
    }
    // If the patient is male, filter out the female one and vice versa
    const genitourinaryFieldFilter =
      infantInfo?.sex == "MALE" ? "genitourinary_female" : "genitourinary_male";
    return NEWBORN_FORM.physical_v2.filter(
      ({ id }) => id != genitourinaryFieldFilter
    );
  }, [infantInfo]);

  useImperativeHandle(ref, () => {
    return {
      getFormData: () => {
        return form.getValues();
      },
      markAllAsNormal: (e: any) => {
        NEWBORN_FORM.physical_v2.map(({ id, options }) => {
          const normalValue = options.find(option => option.id === "normal");
          // @ts-ignore it doesn't know the id is a field id
          form.setValue(id, normalValue);
          e.target.blur();
        });
      },
      resetForm: (values: any) => {
        form.reset(values);
      }
    };
  });

  if (isVersion2) {
    return (
      <>
        {formFields.map(({ id, label, options }) => (
          <div key={id} className={styles.fieldPair}>
            <label className="horizontalLabel">
              <span>{label}</span>
              <Controller
                name={id}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    theme={SelectDefaultTheme}
                    styles={SelectDefaultStyles}
                    onChange={onChange}
                    value={value}
                    options={options}
                  />
                )}
              />
            </label>
            <Input
              type="text"
              name={`${id}-comment`}
              id={`${id}-comment`}
              label="Comment"
              register={form.register}
              isHorizontalLayout={true}
            />
          </div>
        ))}
      </>
    );
  } else {
    return (
      <>
        {NEWBORN_FORM.physical.map(({ id, label, options }) =>
          id === "thorax" ? (
            <div key={id} className={styles.row}>
              <label>{label}</label>
              <ControlledOptionsField
                label={label}
                name={id}
                key={id}
                control={control}
                options={[
                  { id: "yes", label: "Yes" },
                  { id: "no", label: "No" }
                ]}
                hiddenLabel
                disabled={disabled}
              />
            </div>
          ) : (
            <label className="horizontalLabel" key={id}>
              <span>{label}</span>
              <Controller
                name={id}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    theme={SelectDefaultTheme}
                    styles={SelectDefaultStyles}
                    onChange={onChange}
                    value={value}
                    options={options}
                  />
                )}
              />
            </label>
          )
        )}
      </>
    );
  }
});
