/* DayContent Appointment Display */
/* External Imports */
import clsx from "clsx";
import dayjs from "dayjs";
import { sanitize } from "dompurify";
import { MouseEvent } from "react";
import { useMemo } from "react";
import { RootState } from "@/store/store";
import { useSelector } from "react-redux";

/* Local Imports */

// components
import ContentRenderer from "@/components/textArea/contentRenderer";
import Icon from "@/components/icons";

// constants
import {
  APPOINTMENT_STATUS_TYPES,
  METRIC_LABELS,
  TAG_LABELS
} from "@/globals/constants";
import { FORMAT } from "@/globals/helpers/formatters";
import {
  appointmentTypeToHexCode,
  convertUtcIntToLocalDatetime,
  getTextColorForBackground,
  userIdToHexCode
} from "../utils";
import { useColorByProvider } from "@/globals/helpers/customHooks";
import { usePracticeListAppointmentTypesQuery } from "@/store/services/generated/practice";
// store
import { AppointmentInfo } from "@/store/services/scheduling";

// styles
import styles from "../styles.module.scss";
import { getAppointmentLocationIcon } from "@/globals/helpers";
import Tag from "@/components/tag";

/* DayContent Typescript Interface */
interface DayContentProps {
  appointment: AppointmentInfo;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  offsetUnit?: number;
  scheduleEventsOffset?: number;
}

export default function DayContent({
  appointment,
  onClick,
  offsetUnit = 0,
  scheduleEventsOffset = 0
}: DayContentProps) {
  const {
    starts,
    ends,
    patient,
    provider,
    appointment_type,
    chief_complaint: chiefComplaint
  } = appointment;

  // @ts-ignore
  const start = convertUtcIntToLocalDatetime(starts);
  // @ts-ignore
  const end = convertUtcIntToLocalDatetime(ends);

  // get appointment duration in minutes
  const duration = dayjs(end).diff(dayjs(start), "minutes");
  /* Redux */
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const { data: appointmentTypes } = usePracticeListAppointmentTypesQuery();
  const colorByProvider = useColorByProvider();

  /* Local State */
  const locationIcon = useMemo(() => {
    return getAppointmentLocationIcon(
      appointment.appointment_location as string
    );
  }, [appointment.appointment_location]);

  const backgroundColor = useMemo(() => {
    return colorByProvider
      ? userIdToHexCode(appointment.provider.user_id)
      : appointmentTypeToHexCode(
          appointment.appointment_type?.appointment_type,
          appointmentTypes?.find(
            type => type.appointment_type === appointment.appointment_type?.appointment_type
          )?.appointment_hex
        );
  }, [appointment, appointmentTypes, colorByProvider]);

  /* Effects */

  /* Event Handlers */

  return (
    <div className={clsx(styles.DayContent)}>
      <button
        data-cy={`appt-${appointment.appointment_id}`}
        onClick={onClick}
        className={clsx(styles.appointmentCell, {
          [styles.overlapping]: offsetUnit
        })}
        style={{
          height: `calc(${(duration / 15) * 100}% - 2px`,
          width: `calc(100% - 22px - ${
            offsetUnit * 22 + // offset my number of events or appointments
            scheduleEventsOffset * 12 + // offset by number of schedule events
            scheduleEventsOffset +
            1 // offset by 1px for border
          }px`,
          left:
            offsetUnit * 22 + // offset my number of events or appointments
            scheduleEventsOffset * 12 + // offset by number of schedule events
            scheduleEventsOffset +
            1, // offset by 1px for border,
          color: getTextColorForBackground(backgroundColor),
          borderColor: backgroundColor,
          backgroundColor: backgroundColor
        }}
      >
        <div className={styles.wrapper}>
          <div className={styles.nameWrapper}>
            <p className="t5">{FORMAT.name(patient)}</p>
          </div>
          <p className="t5">
            {TAG_LABELS[appointment_type?.appointment_type] || appointment_type?.appointment_type}
          </p>
          <Tag
            label={METRIC_LABELS[appointment.status]}
            type={APPOINTMENT_STATUS_TYPES[appointment.status]}
          />
          <div className={styles.participantBadges}>
            <Icon svg={locationIcon} width={10} height={10} />
            <p className="t5">
              {dayjs(start).format("h:mma")} - {dayjs(end).format("h:mma")}
            </p>
          </div>
          <ContentRenderer
            content={sanitize(chiefComplaint || "")}
            classes="t5"
          />
        </div>
      </button>
    </div>
  );
}
