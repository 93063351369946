/* Newborn form component */
/* External Imports */
import { UseFormReturn, useForm } from "react-hook-form";
import { ReactElement, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";
import Select from "react-select";
/* Local Imports */

// components
import Button from "@/components/button";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";
import Alert from "@/components/alert";
import ControlledCombobox from "@/components/input/controlledCombobox";
import Input from "@/components/input";
import BasicAccordion from "@/components/accordions/basic";

// form components
import NewbornDemographics from "./newbornDemograpics";
import NewbornPhysical from "./newbornPhysical";
import NewbornReflexes from "./newbornReflexes";
import NewbornGrowth from "./newbornGrowth";
import NewbornVitals from "./newbornVitals";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { FORMAT } from "@/globals/helpers/formatters";
import {
  GetNewbornExamDefaultValues,
  NewbornExamEncounterValues,
  NewbornExamLaborValues
} from "./constants";
import { NEWBORN_FORM } from "../constants";

// store
import { usePracticeSearchUsersQuery } from "@/store/services/practice";
import { RootState } from "@/store/store";
import { LaborModel, usePatientGetInfoQuery } from "@/store/services/patient";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";
import { MODAL_TYPES } from "@/components/modal/dispatcher";
import {
  useEncounterInfoQuery,
  useEncounterLaborInfoQuery,
  useLazyEncounterInfoQuery,
  useLazyEncounterLaborInfoQuery
} from "@/store/services/encounter";
import { usePregnancyInfoQuery } from "@/store/services/pregnancy";

// styles
import styles from "../styles.module.scss";
import {
  SelectDefaultStyles,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";

/* Newbornform Typescript Interface */
interface NewbornformProps {
  onSubmit: (data: any) => void;
  disabled?: boolean;
  patientId: number;
  examType: "LABOR" | "ENCOUNTER";
  encounterId: number;
}

/* Interface for the components that make up the newborn exam */
export interface NewbornFormPartsProps {
  form: UseFormReturn<any, any, undefined>;
  disabled?: boolean;
  previousExamData?: NewbornExamEncounterValues | NewbornExamLaborValues;
}

export default function NewbornForm({
  onSubmit,
  disabled = false,
  patientId,
  examType,
  encounterId
}: NewbornformProps) {
  const dispatch = useDispatch();

  const [getLaborData, getLaborResponse] = useLazyEncounterLaborInfoQuery();
  const [getEncounterData, getEncounterResponse] = useLazyEncounterInfoQuery();

  const { data: encounterData } = useEncounterInfoQuery(
    { encounterId },
    { skip: !encounterId }
  );

  const { data: laborData } = useEncounterLaborInfoQuery(
    { encounterId },
    { skip: !encounterId }
  );

  const { data: pregnancyInfo } = usePregnancyInfoQuery(
    {
      pregnancyId: laborData?.pregnancy_id as number
    },
    { skip: !laborData?.pregnancy_id }
  );

  // Autosave isn't currently supported in labor and might cause issues.
  const form = useForm({
    defaultValues: async () => {
      if (examType == "LABOR") {
        const labor = await getLaborData({
          encounterId
        }).unwrap();

        const previousExamData: NewbornExamLaborValues | null =
          (labor?.stages?.immediate_postpartum?.events?.newborn_exam
            ?.forms as NewbornExamLaborValues) || undefined;
        return GetNewbornExamDefaultValues("LABOR", previousExamData);
      } else {
        const encounter = await getEncounterData({ encounterId }).unwrap();
        const previousExamData: NewbornExamEncounterValues | null =
          ((encounter.type_specific as Record<string, any>)
            ?.newborn_exam as NewbornExamEncounterValues) || undefined;
        return GetNewbornExamDefaultValues("ENCOUNTER", previousExamData);
      }
    }
    //mode: examType == "ENCOUNTER" ? "onBlur" : "onSubmit"
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset
  } = form;
  /* Redux */
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  // Local state
  // TODO(Landon): Figure out how to type these better
  const formRefs = useRef<Array<any>>([]);
  const physicalRef = useRef<any>();

  // get all users at the practice
  const { data: users, isSuccess: gotUsers } = usePracticeSearchUsersQuery(
    {
      practiceId: sessionInfo?.practice_id as number
    },
    {
      skip: sessionInfo?.practice_id === undefined
    }
  );

  const { data: motherInfo } = usePatientGetInfoQuery(
    {
      patientId: patientId
    },
    { skip: !patientId }
  );

  let previousExamData;

  /* Effects */
  const providerOptions = useMemo(() => {
    const allUsers = users?.providers.concat(users.medical_assistants);
    return allUsers?.map(user => ({
      label: FORMAT.name(user),
      value: user
    }));
  }, [users]);
  /**
   * We get the previous encounter either from the labor (if we're in labor) or
   * the encounter (if we're in an encounter) since the newborn exam has
   * multiple contexts.
   */
  useEffect(() => {
    if (examType == "LABOR") {
      getLaborData({
        encounterId
      })
        .unwrap()
        .then(labor => {
          previousExamData =
            (labor?.stages?.immediate_postpartum?.events?.newborn_exam
              ?.forms as NewbornExamLaborValues) || undefined;
          reset(GetNewbornExamDefaultValues("LABOR", previousExamData));
          physicalRef.current.resetForm(GetNewbornExamDefaultValues("LABOR", previousExamData));
        });
    } else {
      getEncounterData({ encounterId })
        .unwrap()
        .then(encounter => {
          previousExamData =
            ((encounter.type_specific as Record<string, any>)
              ?.newborn_exam as NewbornExamEncounterValues) || undefined;
          reset(GetNewbornExamDefaultValues("ENCOUNTER", previousExamData));
          physicalRef.current.resetForm(GetNewbornExamDefaultValues("LABOR", previousExamData));
        });
    }

    // If we don't have any provider data, default it to the logged in user
    if (!getValues("select_provider")) {
      const loggedInUser = users?.providers.find(
        user => user.user_id === sessionInfo?.user_id
      );
      if (loggedInUser) {
        setValue("select_provider", loggedInUser);
      }
    }
  }, []);

  useEffect(() => {
    // If we don't have any provider data, default it to the logged in user
    if (!getValues("select_provider")) {
      const loggedInUser = users?.providers.find(
        user => user.user_id === sessionInfo?.user_id
      );
      if (loggedInUser) {
        setValue("select_provider", loggedInUser);
      }
    }
  }, [users]);

  useEffect(() => {
    if (!encounterData || examType === "LABOR") {
      return;
    }
    previousExamData =
      ((encounterData.type_specific as Record<string, any>)
        ?.newborn_exam as NewbornExamEncounterValues) || undefined;
    reset(GetNewbornExamDefaultValues("ENCOUNTER", previousExamData), {
      keepValues: true
    });
    physicalRef.current.resetForm(GetNewbornExamDefaultValues("LABOR", previousExamData));
    // If we don't have any provider data, default it to the logged in user
    if (!getValues("select_provider")) {
      const loggedInUser = users?.providers.find(
        user => user.user_id === sessionInfo?.user_id
      );
      if (loggedInUser) {
        setValue("select_provider", loggedInUser);
      }
    }
  }, [encounterData]);

  const handleCreateInfant = () => {
    dispatch(
      setModalContent({
        type: MODAL_TYPES.CREATE_INFANT,
        props: {
          patientId: motherInfo?.user_id,
          title: "Create infant",
          pregnancyId: motherInfo?.pregnancy?.pregnancy_id,
          defaultValues: { infant_dob: dayjs().format("YYYY-MM-DD") }
        }
      })
    );
    dispatch(setModalIsOpen(true));
  };

  const ourOnSubmit = (data: any) => {
    // Run all of the form part submit functions
    formRefs.current.forEach(formRef => {
      formRef.examPartSubmit(data);
    });

    const physicalExamData =
      physicalRef.current && physicalRef.current.getFormData();

    // Call the onSubmit prop
    onSubmit({ ...physicalExamData, ...data });
  };

  const markAllPhysicalAsNormal = (e: any) => {
    physicalRef.current && physicalRef.current.markAllAsNormal(e);
  };

  if (getLaborResponse.isLoading || getEncounterResponse.isLoading) {
    return (
      <div>
        <Skeleton height="30" />
        <Skeleton height="30" />
        <Skeleton height="30" />
        <Skeleton height="30" />
        <Skeleton height="30" />
        <Skeleton height="30" />
        <Skeleton height="30" />
        <Skeleton height="30" />
        <Skeleton height="30" />
      </div>
    );
  }

  if (examType === "LABOR" && !pregnancyInfo?.infant_id) {
    // This is a labor appointment and we haven't created the infant chart yet
    return (
      <div className={styles.newInfantChartAlert}>
        <Alert
          type={STATUS_KEYS.WARNING}
          message="No infant chart has been created yet."
        />
        <Button style={STYLES.FULL_WIDTH} onClick={handleCreateInfant}>
          Create infant chart
        </Button>
      </div>
    );
  } else {
    return (
      <BasicAccordion
        title="Newborn Physical Exam"
        style={STYLES.SECONDARY}
        open={examType === "LABOR"}
      >
        <form
          className={styles.NewbornForm}
          onBlur={
            examType === "ENCOUNTER" ? handleSubmit(ourOnSubmit) : () => ""
          }
          onSubmit={handleSubmit(ourOnSubmit)}
        >
          <div style={{ display: "none" }}>
            <Input
              type="text"
              value="2"
              name="version"
              id="version"
              label="Version"
              register={form.register}
            />
          </div>
          {examType == "LABOR" && motherInfo && (
            <>
              <Controller
                name="start_time"
                control={control}
                render={({ field: { onChange, name, value } }) => {
                  return (
                    <DateTimeStamp
                      name={name}
                      label={"Exam time"}
                      onChange={onChange}
                      initialValue={value}
                      value={value}
                      disabled={disabled}
                    />
                  );
                }}
              />
              <div className={styles.fieldWrapper}>
                <div className={styles.physical}>
                  <NewbornDemographics
                    form={form}
                    newbornId={pregnancyInfo?.infant_id || patientId}
                    parentId={motherInfo.user_id}
                    ref={ref => (formRefs.current[0] = ref)}
                  />
                </div>
              </div>
              <div className={styles.physical}>
                <NewbornGrowth
                  form={form}
                  newbornId={pregnancyInfo?.infant_id || patientId}
                  encounterId={encounterId}
                  ref={ref => (formRefs.current[1] = ref)}
                />
              </div>
            </>
          )}
          <div className={styles.physical}>
            <NewbornVitals
              form={form}
              disabled={disabled}
              previousExamData={previousExamData}
              examType={examType}
            />
          </div>

          <div className={styles.header}>
            <p>Physical</p>{" "}
            <Button
              type="button"
              onClick={markAllPhysicalAsNormal}
              nativeButtonProps={{ disabled }}
            >
              Mark all as normal
            </Button>
          </div>
          <div className={styles.physical}>
            <NewbornPhysical
              disabled={disabled}
              previousExamData={previousExamData}
              newbornId={pregnancyInfo?.infant_id || patientId}
              ref={ref => (physicalRef.current = ref)}
            />
          </div>
          <div className={styles.header}>Reflexes</div>
          <div className={styles.reflexes}>
            <NewbornReflexes
              form={form}
              disabled={disabled}
            />
          </div>

          {examType == "LABOR" && (
            <>
              <div className={styles.header}>Sign-off</div>
              <Controller
                name="select_provider"
                control={control}
                disabled={disabled}
                render={({ field: { value, onChange } }) => (
                  <Select
                    styles={SelectDefaultStyles}
                    theme={SelectDefaultTheme}
                    options={providerOptions}
                    // @ts-ignore
                    value={providerOptions?.find(
                      item => item.value.user_id === value?.user_id
                    )}
                    //@ts-ignore
                    onChange={newValue => onChange(newValue?.value)}
                  />
                )}
              />
              {errors && errors.select_provider && (
                <p className="error-text">
                  {(errors.select_provider.message as string) ||
                    "Please select a provider from the sign-off dropdown"}
                </p>
              )}
            </>
          )}
          {examType === "LABOR" && (
            <Button
              style={STYLES.FULL_WIDTH}
              type="submit"
              nativeButtonProps={{ disabled }}
            >
              Save
            </Button>
          )}
        </form>
      </BasicAccordion>
    );
  }
}
