/* External */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "@/store/store";
import { setIsAuthenticated } from "@/components/auth/slice";
import { unsetCookie } from "@/utils/cookies";
import queryString from "query-string"; // Import queryString library

/* Local */
import {
  BACKEND_PROTOCOL,
  BACKEND_HOST,
  BACKEND_PORT
} from "@/globals/constants/environment";

export let backendSlice = createApi({
  reducerPath: "backend",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_PROTOCOL}://${BACKEND_HOST}:${BACKEND_PORT}/api/v1/`,
    credentials: "include",
    fetchFn: async (input, init) => {
      const response = await fetch(input, init);
      if (!response.ok && response.status === 401) {
        console.error("NOT AUTHENTICATED - LOGGING USER OUT");
        unsetCookie("X-Pario-Auth");
        store.dispatch(setIsAuthenticated(false));
        window.Intercom &&
          window.Intercom("update", {
            hide_default_launcher: true
          });
      } else {
        window.Intercom &&
          window.Intercom("update", {
            hide_default_launcher: false
          });
      }
      return response;
    },
    // Configure paramsSerializer to serialize arrays using "none" style
    paramsSerializer: params =>
      queryString.stringify(params, { arrayFormat: "none" })
  }),
  tagTypes: [
    "LabBundles",
    "Providers",
    "Appointments",
    "Problems",
    "Patients",
    "Encounter",
    "Files",
    "Orgs",
    "Inbox",
    "Phrases",
    "Tasks",
    "Forms",
    "Events",
    "Transactions",
    "InfantFlowSheet",
    "Claim",
    "ClaimOutcome",
    "EDD",
    "Tags",
    "FileAnnotations",
    "LabFlowsheet",
    "LabOrder",
    "OrderAnnotations"
  ],
  endpoints: _builder => ({})
});

// TODO: move to separate slices / locations by using endpoint injection
// ref: https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced#injecting-endpoints
